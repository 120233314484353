// eslint-disable-next-line import/no-relative-parent-imports, import/no-unassigned-import
import '../scss/preheader.scss';

interface KeyValueObj {
    [key: string]: string
}
interface KeyNumberObj {
    [key: string]: number
}
interface Labels {
    [key: string]: {
        [key: string]: string
    }
}
interface BrandContent {
    key: string,
    image: string,
    content: {
        [key: string]: {
            [key: string]: string
        }
    }
}
interface PreHeaderData {
    toggleRowImage: string,
    mobileBgImage: string,
    staticContent: {
        [key: string]: {
            [key: string]: string
        }
    },
    brandData: BrandContent[]
}

class PreHeader extends HTMLElement {
    /**
     * Component attributes
     */

    siteBrand = '';
    lang = '';
    resourcesBasePath = 'https://www.kuoni.ch/fileadmin/ext/preheader/';
    cssFile = 'https://www.kuoni.ch/resources/preheader.css';
    dataFile = 'brand-data.json';

    resourceTypePath: KeyValueObj = {
        image: 'img/',
        css: 'css/',
        data: 'data/'
    };

    mobileBgGradient = 'linear-gradient(to bottom, #212121 0%, rgba(33, 33, 33, 0) 25%, rgba(33, 33, 33, 0) 75%, #212121 100%), linear-gradient(to right, #212121 0%, rgba(33, 33, 33, 0) 25%, rgba(33, 33, 33, 0) 75%, #212121 100%)';

    templates: KeyValueObj = {
        toggleRow: `<div class="toggle-row">
                        <div class="container">
                            <a class="js-toggle">
                                <span class="label"></span>
                                <img src="" width="116" height="28" alt="" />
                                <i></i>
                            </a>
                        </div>
                    </div>`,
        dropdown: `<div class="dropdown">
                    <div class="container" id="dropdown-panel">
                        <div class="content-static">
                            <div id="content-main">
                                <p class="title"></p>
                                <p><a class="arrow" target="_blank"></a></p>
                            </div>
                            <div id="slider-nav"></div>
                        </div>
                    </div>
                </div>`
    };

    labels: Labels = {
        de: {
            weArePartOf: 'Wir sind Teil der',
            moreInfo: 'Mehr erfahren',
            visitWebsite: 'Website besuchen',
            ourTravelExperts: 'Unsere Reisespezialisten'
        },
        fr: {
            weArePartOf: 'Nous faisons partie des',
            moreInfo: 'En savoir plus',
            visitWebsite: 'Visite website',
            ourTravelExperts: 'Nos spécialistes du voyage'
        }
    };

    data: PreHeaderData = {
        toggleRowImage: '',
        mobileBgImage: '',
        staticContent: {},
        brandData: []
    };

    brandIndexMap: KeyNumberObj = {};

    isSliderInitialized = false;
    isBrandNavInitialized = false;

    firstBrand = '';
    activeIndex = 0;
    activeBrand = '';

    sliderIntervalId: NodeJS.Timer|null = null;
    autoSlideCount = 1;
    autoSlideStopped = false;

    mainContainer: HTMLElement|null = null;
    slideProgressBar: HTMLDivElement|null = null;
    slideLinkList: HTMLUListElement|null = null;
    sliderContainer: HTMLUListElement|null = null;

    trackingEvent: string = 'preheader_click';

    isMobileView: boolean = false;

    /**
     * Default web component framework methods
     */

    static get observedAttributes (): string[] {
        return [
            'site-brand',
            'resources-base-path',
            'css-file',
            'data-file',
            'lang'
        ];
    }

    attributeChangedCallback (property: string, oldValue: string, newValue: string): void {
        if (oldValue === newValue || newValue === '') {
            return;
        }

        switch (property) {
            case 'site-brand':
                this.siteBrand = newValue;
                break;
            case 'resources-base-path':
                this.resourcesBasePath = newValue;
                break;
            case 'css-file':
                this.cssFile = newValue;
                break;
            case 'data-file':
                this.dataFile = newValue;
                break;
            case 'lang':
                this.setLang(newValue);
                break;
            default:
                break;
        }
    }

    connectedCallback (): void {
        // if not provided as attribute of the custom element, get lang from document element
        if (this.lang === '') {
            if (document.documentElement.lang) {
                // if lang is set to e.g. 'de-CH', reduce it to 'de'
                this.setLang(document.documentElement.lang.substring(0, 2));
            }
        }

        const shadow = this.attachShadow({ mode: 'closed' });
        shadow.innerHTML = `
          <link rel="stylesheet" href="${this.getResourceUrl('css', this.cssFile)}" media="all">
          <div class="ph" id="preheader"></div>
        `;
        this.mainContainer = shadow.getElementById('preheader');

        this.loadData();
    }

    /**
     * Special getter/setter
     */

    setLang (lang: string): void {
        if (!Object.prototype.hasOwnProperty.call(this.labels, lang)) {
            // fallback language
            this.lang = 'de';
        } else {
            this.lang = lang;
        }
    }

    getLabel (key: string): string {
        if (!Object.prototype.hasOwnProperty.call(this.labels, this.lang) || !Object.prototype.hasOwnProperty.call(this.labels[this.lang], key)) {
            return '';
        }
        return this.labels[this.lang][key];
    }

    getResourceUrl (type: string, path: string): string {
        if (path.startsWith('/') || path.startsWith('https://')) {
            return path;
        }
        return `${this.resourcesBasePath}${this.resourceTypePath[type]}${path}`;
    }

    /**
     * Loading data and templates
     */

    loadData (): void {
        // eslint-disable-next-line  @typescript-eslint/no-floating-promises
        fetch(this.getResourceUrl('data', this.dataFile))
            .then((response) => {
                return response.json();
            })
            .then((data: PreHeaderData) => {
                let siteBrandIndex = null;
                let j = 0;
                this.brandIndexMap = {};
                for (let i = 0; i < data.brandData.length; i++) {
                    if (this.siteBrand && data.brandData[i].key === this.siteBrand) {
                        siteBrandIndex = i;
                    } else {
                        this.brandIndexMap[data.brandData[i].key] = j;
                        j++;
                    }
                }

                // remove brand of current site, defined via site-brand attribute
                if (siteBrandIndex !== null) {
                    data.brandData.splice(siteBrandIndex, 1);
                }

                this.data = data;

                this.addToggleRow();
                this.addDropdownContainer();

                return data;
            })
            .catch((error) => {
                console.log('Error loading pre-header data.', error);
            });
    }

    loadTemplate (id: string, templateKey: string): DocumentFragment {
        let templateElement = null;
        // load from template defined in website
        templateElement = <HTMLTemplateElement>document.getElementById(id);
        if (templateElement) {
            return <DocumentFragment>templateElement.content.cloneNode(true);
        }
        // load from string in class
        templateElement = document.createElement('template');
        templateElement.innerHTML = this.templates[templateKey];
        return document.importNode(templateElement.content, true);
    }

    /**
     * Component initialization methods
     */

    addToggleRow (): void {
        const template = this.loadTemplate('ph-togglerow', 'toggleRow');

        const toggleLink = template.querySelector('.js-toggle');
        if (!toggleLink) {
            return;
        }
        const label = <HTMLSpanElement>toggleLink.querySelector('span.label');
        if (label) {
            label.innerText = this.getLabel('weArePartOf');
        }
        toggleLink.addEventListener('click', () => {
            this.toggleDropdown();
        });
        const img = template.querySelector('img');
        if (img) {
            img.src = this.getResourceUrl('image', this.data.toggleRowImage);
        }
        if (this.mainContainer) {
            this.mainContainer.append(template);
        }
    }

    addDropdownContainer (): void {
        const template = this.loadTemplate('ph-dropdown', 'dropdown');
        const staticContentContainer = template.querySelector('#content-main');
        if (!staticContentContainer) {
            return;
        }
        const titleElem = staticContentContainer.querySelector('.title');
        if (titleElem) {
            titleElem.innerHTML = this.data.staticContent[this.lang].title;
            titleElem.insertAdjacentHTML('afterend', this.data.staticContent[this.lang].text);
        }

        const linkElem = staticContentContainer.querySelector('a');
        if (linkElem) {
            linkElem.innerText = this.getLabel('moreInfo');
            linkElem.setAttribute('href', this.data.staticContent[this.lang].url);
        }

        const sliderNavTitle = this.createParagraphElem(this.getLabel('ourTravelExperts'), 'title ph-title--slider-nav');
        staticContentContainer.after(sliderNavTitle);

        const panelContainer = <HTMLDivElement>template.querySelector('#dropdown-panel');
        if (panelContainer) {
            panelContainer.style.backgroundImage = `${this.mobileBgGradient}, url(${this.getResourceUrl('image', this.data.mobileBgImage)})`;
        }

        if (this.mainContainer) {
            this.mainContainer.append(template);
        }
    }

    createParagraphElem (content: string, classAttrib = ''): HTMLParagraphElement {
        const pTag = document.createElement('p');
        if (classAttrib) {
            pTag.className = classAttrib;
        }
        pTag.innerText = content;
        return pTag;
    }

    createBrandNav (): void {
        if (!this.mainContainer) {
            return;
        }
        this.slideProgressBar = this.createSlideProgressBar();

        const navContainer = this.mainContainer.querySelector('#slider-nav');
        if (!navContainer) {
            return;
        }
        navContainer.appendChild(this.slideProgressBar);

        this.slideLinkList = document.createElement('ul');
        this.data.brandData.forEach((brandContent, index) => {
            if (this.slideLinkList) {
                this.slideLinkList.appendChild(this.createBrandNavElem(index, brandContent));
            }
        });
        navContainer.appendChild(this.slideLinkList);
        this.isBrandNavInitialized = true;
    }

    createBrandNavElem (index: number, brandContent: BrandContent): HTMLLIElement {
        const slideLink = document.createElement('a');
        slideLink.setAttribute('data-brand', brandContent.key);
        slideLink.setAttribute('data-link', brandContent.content[this.lang].url);
        slideLink.id = `slide-link-${brandContent.key}`;
        if (index === 0) {
            slideLink.classList.add('active');
        }
        slideLink.innerText = brandContent.content[this.lang].title;
        slideLink.addEventListener('click', () => {
            if (this.isMobileView) {
                this.crossLink(brandContent.key, brandContent.content[this.lang].url);
            } else {
                this.trackEvent(`click ${brandContent.key}`);
                this.showSlide(`${brandContent.key}`);
            }
        });

        const slideLinkListItem = document.createElement('li');
        slideLinkListItem.appendChild(slideLink);

        return slideLinkListItem;
    }

    createBrandSlider (): void {
        this.sliderContainer = document.createElement('ul');
        this.sliderContainer.className = 'brand-slides';

        this.data.brandData.forEach((brandDataContent, index) => {
            if (this.sliderContainer) {
                this.sliderContainer.appendChild(this.createSlideElem(index, brandDataContent));
            }
        });

        if (this.mainContainer) {
            const panelContainer = this.mainContainer.querySelector('#dropdown-panel');
            if (panelContainer) {
                panelContainer.appendChild(this.sliderContainer);
            }
        }

        this.isSliderInitialized = true;
    }

    createSlideElem (index: number, brandContent: BrandContent): HTMLLIElement {
        // create spacer div
        const spacerContainer = document.createElement('div');
        spacerContainer.className = 'spacer';

        // create content
        const contentContainer = document.createElement('div');
        contentContainer.className = 'brand-content';
        contentContainer.appendChild(this.createParagraphElem(brandContent.content[this.lang].title, 'title'));
        contentContainer.appendChild(this.createParagraphElem(brandContent.content[this.lang].lead, 'lead'));
        contentContainer.appendChild(this.createParagraphElem(brandContent.content[this.lang].text));

        const linkTag = document.createElement('a');
        linkTag.className = 'arrow';
        // linkTag.setAttribute('href', brandContent.content[this.lang].url);
        // linkTag.setAttribute('target', '_blank');
        linkTag.innerText = this.getLabel('visitWebsite');
        linkTag.addEventListener('click', () => {
            this.crossLink(brandContent.key, brandContent.content[this.lang].url);
        });

        const pLinkTag = document.createElement('p');
        pLinkTag.appendChild(linkTag);
        contentContainer.appendChild(pLinkTag);

        const sliderElem = document.createElement('li');
        const translateX = (index * 100);
        sliderElem.setAttribute('style', `transform: translateX(-${translateX}%); background-image: url('${this.getResourceUrl('image', brandContent.image)}')`);
        sliderElem.id = `slide-${brandContent.key}`;
        if (index === 0) {
            sliderElem.classList.add('active');
            this.activeIndex = 0;
            this.activeBrand = brandContent.key;
            this.firstBrand = brandContent.key;
        }
        sliderElem.appendChild(spacerContainer);
        sliderElem.appendChild(contentContainer);

        return sliderElem;
    }

    createSlideProgressBar (): HTMLDivElement {
        const progressBar = document.createElement('div');
        const progressBarInner = document.createElement('div');

        progressBar.className = 'slider-progress';
        progressBar.appendChild(progressBarInner);

        return progressBar;
    }

    /**
     * Runtime methods
     */

    toggleDropdown (): void {
        if (!this.isBrandNavInitialized) {
            this.createBrandNav();
        }
        if (window.innerWidth >= 992) {
            if (!this.isSliderInitialized) {
                this.createBrandSlider();
            }
            if (this.mainContainer && this.mainContainer.classList.contains('active')) {
                this.stopSlider();
            } else {
                if (!this.autoSlideStopped) {
                    this.startSlider();
                }
            }
            this.isMobileView = false;
        } else {
            this.isMobileView = true;
        }
        if (this.mainContainer) {
            // tracking: open event
            if (!this.mainContainer.classList.contains('active')) {
                this.trackEvent('opened');
            }
            this.mainContainer.classList.toggle('active');
        }
    }

    startSlider (): void {
        this.sliderIntervalId = setInterval(() => { this.nextSlide(); }, 4000);
        if (this.slideProgressBar) {
            const slideProgressBarInner = this.slideProgressBar.querySelector('div');
            if (slideProgressBarInner) {
                slideProgressBarInner.style.animationName = 'progress';
            }
        }
    }

    stopSlider (): void {
        if (this.sliderIntervalId) {
            clearInterval(this.sliderIntervalId);
        }
        if (!this.slideProgressBar) {
            return;
        }
        const slideProgressBarInner = this.slideProgressBar.querySelector('div');
        if (slideProgressBarInner) {
            slideProgressBarInner.style.animationName = 'unset';
        }
    }

    showSlide (brandKey: string, stopSlider = true): void {
        if (!this.sliderContainer) {
            return;
        }
        // set active slides
        const slideElements = this.sliderContainer.querySelectorAll('li');
        slideElements.forEach((slideElem) => {
            slideElem.classList.remove('active');
        });
        const slide = this.sliderContainer.querySelector(`#slide-${brandKey}`);
        if (slide) {
            slide.classList.add('active');
        }

        // set active brand nav
        if (!this.slideLinkList) {
            return;
        }
        const sliderNavLinks = this.slideLinkList.querySelectorAll('a');
        sliderNavLinks.forEach((slideNavLink) => {
            slideNavLink.classList.remove('active');
        });
        const slideNav = this.slideLinkList.querySelector(`a#slide-link-${brandKey}`);
        if (slideNav) {
            slideNav.classList.add('active');
        }

        this.activeBrand = brandKey;
        this.activeIndex = this.brandIndexMap[brandKey];

        if (stopSlider) {
            this.stopSlider();
        }
    }

    nextSlide (): void {
        let nextIndex = (this.activeIndex + 1);
        if (this.activeIndex === (this.data.brandData.length - 1)) {
            nextIndex = 0;
        }
        const nextBrand = this.data.brandData[nextIndex].key;
        this.showSlide(nextBrand, false);

        this.autoSlideCount++;
        if (this.autoSlideCount === this.data.brandData.length) {
            this.stopSlider();
            this.autoSlideStopped = true;
        }
    }

    crossLink (brand: string, url: string): void {
        this.trackEvent(`cross-link ${brand}`);
        window.open(url);
    }

    trackEvent (action: string): void {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: this.trackingEvent, action });
    }
}

window.customElements.define('pre-header', PreHeader);
